<template>
<h1 class="text-center">AttrPlugin</h1>
  <h1 class="pb-2 text-center">NR.1</h1>
  <AttrPluginNr1/>

</template>

<script>

import AttrPluginNr1 from '@/components/animations/AttrPlugin/AttrPluginNr1.vue'

export default {
  name: "AttrPlugin",
  components:{ AttrPluginNr1}
}
</script>

<style scoped>

</style>
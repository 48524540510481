<template>
  <svg class="mx-auto" id="logo" xmlns="http://www.w3.org/2000/svg" width="600" height="179" viewBox="0 0 600 179">
    <g id="logoVidelec" transform="translate(35 -0.001)">
      <g id="vi">
        <path id="v" class="myBlue" d="M603.234,397.035a2.992,2.992,0,0,1,3.147-2.407h5.676a1.84,1.84,0,0,1,1.728,2.283l-12.341,44.3q-3.086,10.984-15.8,10.985-12.648,0-15.734-10.985l-18.768-64.328a1.758,1.758,0,0,1,1.727-2.222h1.975q6.171,0,7.836,5.924l17.472,59.577q1.049,3.824,5.492,3.826,4.5,0,5.554-3.826Z" transform="translate(-373.843 -314.827)"/>
        <path id="i" class="myBlue" d="M598.6,442.914a8.007,8.007,0,0,1-8.083-8.084V388.614a2.155,2.155,0,0,1,2.4-2.469h5.245a2.183,2.183,0,0,1,2.469,2.469v47.817a3.775,3.775,0,0,1,2.838,1.235,3.872,3.872,0,0,1,1.172,2.84v.433a1.745,1.745,0,0,1-1.974,1.975Z" transform="translate(-344.694 -306.344)"/>
      </g>
      <g id="delec">
        <path id="d"  class="myBlue" d="M655.917,451.775a7.4,7.4,0,0,1-7.589-5.307q-7.035,6.972-18.511,6.538a31.328,31.328,0,0,1-11.042-2.344,25.189,25.189,0,0,1-12.527-10.736,34.247,34.247,0,0,1,0-33.072,25.277,25.277,0,0,1,12.527-10.675,31.363,31.363,0,0,1,11.042-2.344,25.724,25.724,0,0,1,13.388,2.961q3.644,1.912,4.629,1.913V382.234a8,8,0,0,1,8.083-8.082h.433a1.417,1.417,0,0,1,1.6,1.6V450.17a1.418,1.418,0,0,1-1.6,1.6Zm-8.083-37.887a7.845,7.845,0,0,0-1.358-4.443c-.163-.247-.329-.492-.494-.739a16.905,16.905,0,0,0-10.3-6.418,19.5,19.5,0,0,0-12.338,1.3q-10.861,5-10.861,19.806,0,14.87,10.861,19.808a19.223,19.223,0,0,0,12.338,1.356,16.9,16.9,0,0,0,10.3-6.417,15.065,15.065,0,0,0,1.852-3.269Z" transform="translate(-336.216 -315.206)"/>
        <path id="e2" class="myBlue" d="M690.362,407.4a13.8,13.8,0,0,1-4.445,8.455,14.147,14.147,0,0,1-9.623,3.269H652.229q-3.023,0-4.257-1.482a21.723,21.723,0,0,0,1.974,7.53q4.872,10.428,16.6,11.477a25.764,25.764,0,0,0,10.181-1.174q5.676-1.787,8.392-5.243a1.513,1.513,0,0,1,1.788-.494,1.489,1.489,0,0,1,1.115,1.542v1.3a9.127,9.127,0,0,1-2.842,6.663q-5.368,5.428-17.4,5.427-14.007,0-22.213-8.635-7.776-8.206-7.775-20.98,0-10.611,4.565-17.831,7.468-11.785,23.633-11.786a31.807,31.807,0,0,1,13.636,2.777q8.389,3.949,10.428,12.341a16.813,16.813,0,0,1,.308,6.848m-35.418-10.3a15.543,15.543,0,0,0-5.985,9.934q-.8,4.009,2.16,4.01h21.164q4.938,0,6.6-1.666a7.45,7.45,0,0,0,1.85-5.615,9.292,9.292,0,0,0-1.3-4.319q-3.639-5.861-13.39-5.862a17.822,17.822,0,0,0-11.106,3.518" transform="translate(-309.764 -306.869)"/>
        <path id="e" class="myBlue" d="M734.03,407.4a13.8,13.8,0,0,1-4.443,8.455,14.156,14.156,0,0,1-9.627,3.269H695.9q-3.023,0-4.257-1.482a21.725,21.725,0,0,0,1.972,7.53q4.875,10.428,16.6,11.477a25.764,25.764,0,0,0,10.181-1.174q5.673-1.787,8.392-5.243a1.512,1.512,0,0,1,1.788-.494,1.488,1.488,0,0,1,1.111,1.542v1.3a9.124,9.124,0,0,1-2.838,6.663q-5.37,5.428-17.4,5.427-14,0-22.211-8.635-7.778-8.206-7.776-20.98,0-10.611,4.566-17.831,7.465-11.785,23.634-11.786a31.813,31.813,0,0,1,13.636,2.777q8.389,3.949,10.427,12.341a16.787,16.787,0,0,1,.309,6.848m-35.418-10.3a15.543,15.543,0,0,0-5.985,9.934q-.8,4.009,2.16,4.01H715.95q4.935,0,6.6-1.666a7.456,7.456,0,0,0,1.85-5.615,9.313,9.313,0,0,0-1.3-4.319q-3.641-5.861-13.39-5.862a17.814,17.814,0,0,0-11.106,3.518" transform="translate(-277.499 -306.869)"/>
        <path id="l" class="myBlue" d="M673.329,374.152a8,8,0,0,1,8.083,8.082v63.06a3.964,3.964,0,0,1,2.9,1.235,3.859,3.859,0,0,1,1.172,2.838v.433a1.773,1.773,0,0,1-2.036,1.975h-4.073a7.745,7.745,0,0,1-5.676-2.346,7.841,7.841,0,0,1-2.407-5.738V375.755a1.417,1.417,0,0,1,1.605-1.6Z" transform="translate(-285.009 -315.206)"/>
        <path id="c" class="myBlue" d="M743.917,393.555q-9.009,0-14.254,6.295-4.873,5.924-4.874,15.177,0,9.317,4.874,15.242,5.243,6.294,14.254,6.293,10.426,0,15.424-6.354a1.517,1.517,0,0,1,1.789-.5,1.491,1.491,0,0,1,1.111,1.544v1.3a9.4,9.4,0,0,1-2.652,6.85q-5.245,5.243-15.673,5.243-14.007,0-22.029-8.637-7.59-8.143-7.589-20.98,0-12.771,7.589-20.917c5.347-5.757,12.776-8.761,22.029-8.637s0,8.082,0,8.082" transform="translate(-253.231 -306.844)"/>
      </g>
      <path id="patrOfV" class="myGreen" d="M585.648,443.9q-4.442,0-5.492-3.826l-17.472-59.584q-1.667-5.921-7.836-5.923h-1.975a1.759,1.759,0,0,0-1.727,2.222l18.768,64.329q3.083,10.986,15.734,10.986c.11,0,.205-.014.313-.017v-8.227c-.113,0-.2.038-.313.038" transform="translate(-373.843 -314.896)"/>

      <path id="greenDot" class="myGreen" d="M600.774,375.655a6.19,6.19,0,0,1,1.542,4.382,6.412,6.412,0,0,1-1.542,4.441,6.864,6.864,0,0,1-9.38,0,6.275,6.275,0,0,1-1.6-4.441,6.459,6.459,0,0,1,10.983-4.382" transform="translate(-345.232 -315.462)"/>
      <path id="signet" class="myGreen" d="M4868.565,883.125a69.242,69.242,0,0,1-38.894-11.88,69.763,69.763,0,0,1-25.2-30.6,69.47,69.47,0,0,1,6.414-65.97,69.773,69.773,0,0,1,30.607-25.2,69.469,69.469,0,0,1,65.971,6.414,69.76,69.76,0,0,1,25.2,30.606,69.473,69.473,0,0,1-6.413,65.97,69.764,69.764,0,0,1-30.6,25.2A69.133,69.133,0,0,1,4868.565,883.125Zm-36.191-99.482a1.665,1.665,0,0,0-1.425.68,1.64,1.64,0,0,0-.308,1.55l18.825,64.541a14.458,14.458,0,0,0,5.495,8.265c2.62,1.829,6.083,2.757,10.293,2.757.063,0,.121-.005.178-.01.042,0,.089-.008.137-.01v-8.251h-.008a.856.856,0,0,0-.149.016.892.892,0,0,1-.158.017,6.212,6.212,0,0,1-3.606-.96,4.966,4.966,0,0,1-1.9-2.88l-17.531-59.772a7.434,7.434,0,0,0-7.859-5.945Zm72.054-1.335a5.944,5.944,0,0,0-6.315,6.249,6.326,6.326,0,0,0,1.61,4.459,6.881,6.881,0,0,0,9.408,0,6.47,6.47,0,0,0,1.55-4.459,6.233,6.233,0,0,0-1.55-4.393A6.135,6.135,0,0,0,4904.428,782.309Z" transform="translate(-4779 -724)"/>
    </g>
    <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-4" width="600" height="179"/>
  </svg>
</template>

<script>

import {gsap} from "gsap";
import {CustomEase} from 'gsap/CustomEase'
import {CustomWiggle} from 'gsap/CustomWiggle'

gsap.registerPlugin(CustomEase,CustomWiggle)
export default {
  name: "AttrPluginNr1",


  mounted() {


    const logoAnimation = gsap.timeline();

    logoAnimation
        .to('#logo', .0001, {opacity:1})
        .to('#v', .0001, {x: "+=36"})
        .to('#i', .0001, {x: "+=40"})
        .from('#logoVidelec', 1, {scale:.0001, transformOrigin:"80px 70px", ease: "elastic(1,0.4)"})
        .from('#signet', 2, {x: "+=167", ease: "elastic(1,0.4)"})
        .from('#delec', 2, {x: "+=167", ease: "elastic(1,0.4)"}, "-=2")
        .from('#patrOfV', .1, {scale: 1.2, y: "-=14", ease: "expo.inOut"}, "-=2")
        .from('#greenDot', .1, {scale: 1.2,  ease: "expo.inOut"}, "-=2")

        .from('#patrOfV', .1, {x: "+=36",  ease: "expo.inOut"}, "-=2")
        .from('#greenDot', .1, {x: "+=40", y: "-=1",  ease: "expo.inOut"}, "-=2")
        .to('#v', .1, {x: "-=36",  ease: "expo.inOut"},  "-=2")
        .to('#i', .1, {x: "-=40",  ease: "expo.inOut"},  "-=2")

        .from('#c', 2, {x: "-=370", ease: "elastic(1,0.4)"},  "-=2")
        .from('#e', 2, {x: "-=325", ease: "elastic(1,0.4)"},  "-=2")
        .from('#l', 2, {x: "-=300", ease: "elastic(1,0.4)"},  "-=2")
        .from('#e2', 2, {x: "-=240", ease: "elastic(1,0.4)"},  "-=2")
        .from('#d', 2, {x: "-=180", ease: "elastic(1,0.4)"},  "-=2");

}}
</script>

<style scoped>

#logo {opacity:0}
.content {
  position: relative;
  height: 100vh;
  width:100vw;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.myBlue {
  fill: #1e438c;
}
.myGreen {
  fill: #40a2a1;
}
.myGreen, .myBlue {
  stroke: rgba(0,0,0,0);
}
.cls-4 {
  fill: none;
}

</style>